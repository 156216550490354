<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="generalKasbon"
          :employees="employees"
          :listAccountNumber="listAccountNumber"
          :listCategory="listCategory"
          :listSite="listSite"
          :dropDownCurrency="dropDownCurrency"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail
          :id="id"
          :form="generalKasbon"
          :listAccountNumber="listAccountNumber"
          v-if="edit"
        ></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="printPreview">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>Print Kasbon</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && generalKasbon.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openLinkWo">
                  <v-icon>
                    mdi-link
                  </v-icon>
                </v-btn>
              </template>
              <span>Link WO</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="warning" v-on="on" @click="openLinkDo">
                  <v-icon>
                    mdi-link
                  </v-icon>
                </v-btn>
              </template>
              <span>Link DO</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && generalKasbon.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="generalKasbon.formNumber"
      title="General Kasbon"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
    <link-wo
      :dialog="dialogWo"
      :id="id"
      :listWo="listWo"
      :items="generalKasbon.wos"
      @close="close"
      @submit="saveLinkWo"
    ></link-wo>
    <kasbon-link-do
      :dialog="dialogDo"
      :id="id"
      :listDo="listDo"
      :items="generalKasbon.dos"
      @close="close"
      @submit="saveLinkDo"
    ></kasbon-link-do>
  </form>
</template>

<script>
import Detail from "./DetailGeneralKasbon";
import FormEdit from "./FormGeneralKasbon";
import DialogDelete from "@/components/DialogDelete";
import LinkWo from "./LinkWo.vue";
import KasbonLinkDo from "./KasbonLinkDo.vue";
import { mapState } from "vuex";

export default {
  name: "general-kasbon-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
    LinkWo,
    KasbonLinkDo,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    listCategory: [],
    listSite: [],
    employees: [],
    generalKasbon: {},
    listWo: [],
    listDo: [],
    dialogWo: false,
    dialogDo: false,
  }),
  computed: {
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("generalKasbon", ["listAccountNumber"]),
  },
  methods: {
    async getGeneralKasbon() {
      await this.$store.dispatch("generalKasbon/getById", this.id).then(async response => {
        this.generalKasbon = response.data;
        await this.$store.dispatch("generalKasbon/getListAccountNumber", response.data.employeeId);
        this.getWos(response.data.employeeId);
      });
    },
    async getEmployee() {
      await this.$store
        .dispatch("external/getEmployees")
        .then(response => (this.employees = response.data));
    },
    async getCategory() {
      const response = await this.$store.dispatch("generalKasbon/getListCategory");
      if (response.status === 200) {
        this.listCategory = response.data;
      }
    },
    async getListSite() {
      const response = await this.$store.dispatch("external/getSiteAll");
      if (response.status === 200) {
        this.listSite = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("generalKasbon/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = this.dialogWo = this.dialogDo = false;
    },
    cancel() {
      this.getGeneralKasbon();
      this.edit = true;
    },
    openLinkWo() {
      this.dialogWo = true;
    },
    openLinkDo() {
      this.dialogDo = true;
    },
    printPreview() {
      this.$router.push({ name: "Print Preview Kasbon", params: { id: this.id } });
    },
    async getWos(employeeId) {
      await this.$store
        .dispatch("wo/getByEmployeeId", employeeId)
        .then(response => (this.listWo = response.data));
    },
    async getListDO() {
      await this.$store
        .dispatch("generalKasbon/getListDo")
        .then(response => (this.listDo = response.data));
    },
    async saveLinkWo(items) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("generalKasbon/linkWo", { id: this.id, body: items })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async saveLinkDo(items) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("generalKasbon/linkDo", { id: this.id, body: items })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("currency/getListCurrency"),
        this.getCategory(),
        this.getEmployee(),
        this.getGeneralKasbon(),
        this.getListDO(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
