<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Link WO</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>WO Number</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    dense
                    outlined
                    flat
                    background-color="white"
                    append-icon="mdi-magnify"
                    :items="listWo"
                    item-text="id"
                    return-object
                    v-model="wo"
                    :filter="filterObjectWo"
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ data.item.id }} ( {{ woDate(data.item.tasks) }})</v-list-item-title
                        >
                        <v-list-item-subtitle>{{ woSite(data.item.tasks) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-btn color="success" @click="addWo">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-data-table
                :headers="headers"
                :items="items"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.no="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.action="{ index, item }">
                  <v-btn icon @click="deleteWo(index, item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="submit">
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-link-wo",
  props: {
    dialog: Boolean,
    id: Number,
    listWo: Array,
    items: Array,
  },
  data() {
    return {
      valid: true,
      wo: {},
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "WO Number",
          value: "woId",
          sortable: false,
          divider: true,
        },
        {
          text: "Site",
          value: "sites",
          sortable: false,
          divider: true,
        },
        {
          text: "Date",
          value: "dates",
          sortable: false,
          divider: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
        },
      ],
    };
  },
  methods: {
    async deleteWo(index, id) {
      if (id) {
        await this.$store.dispatch("generalKasbon/deleteLinkWo", id);
      }
      this.items.splice(index, 1);
    },
    async addWo() {
      if (this.items.findIndex(x => x.woId === this.wo.id) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "WO sudah digunakan",
        });
      } else {
        this.items.push({
          woId: this.wo.id,
          sites: this.woSite(this.wo.tasks),
          dates: this.woDate(this.wo.tasks),
        });
      }
    },
    woSite(tasks) {
      let siteName = [];
      if (tasks && tasks.length > 0) {
        tasks.map(x => {
          if (x.trips && x.trips.length > 0) {
            return x.trips.forEach(y => {
              siteName.push(y.siteName);
            });
          }
        });
        return siteName.filter((v, i, s) => s.indexOf(v) === i).join(",");
      }
      return "";
    },
    woDate(tasks) {
      if (tasks && tasks.length > 0) {
        tasks.map(x => x.date).join(",");
        return tasks
          .map(x => x.date)
          .filter((v, i, s) => s.indexOf(v) === i)
          .join(",");
      }
      return "";
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectWo(item, queryText, itemText) {
      return (
        item.id.toString().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        this.woSite(item.tasks)
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        this.woDate(item.tasks)
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async submit() {
      this.$emit("submit", this.items);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
