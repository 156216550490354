<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Link DO</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                  <v-subheader>DO Number</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    dense
                    outlined
                    flat
                    background-color="white"
                    append-icon="mdi-magnify"
                    :items="listDo"
                    item-text="doName"
                    return-object
                    v-model="linkDo"
                  >
                  </v-autocomplete>
                </v-col>
                <v-btn color="success" @click="addDo">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-data-table
                :headers="headers"
                :items="items"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:item.no="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.action="{ index, item }">
                  <v-btn icon @click="deleteDo(index, item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="primary" @click="submit">
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-link-do",
  props: {
    dialog: Boolean,
    id: Number,
    listDo: Array,
    items: Array,
  },
  data() {
    return {
      valid: true,
      linkDo: {},
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
        },
        {
          text: "DO Number",
          value: "doName",
          sortable: false,
          divider: true,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          divider: true,
        },
      ],
    };
  },
  methods: {
    async deleteDo(index, id) {
      if (id) {
        await this.$store.dispatch("generalKasbon/deleteLinkDo", id);
      }
      this.items.splice(index, 1);
    },
    async addDo() {
      if (this.items.findIndex(x => x.doid === this.linkDo.doId) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "DO sudah digunakan",
        });
      } else {
        this.items.push(this.linkDo);
      }
    },
    async submit() {
      this.$emit("submit", this.items);
      this.$emit("close");
    },
    close() {
      this.linkDo = {};
      this.$emit("close");
    },
  },
};
</script>

<style></style>
